import { useIntl } from 'react-intl';
import winterTrackImage from '../../../assets/images/tracks/Winter-track.webp';
import winterTrackImage2x from '../../../assets/images/tracks/Winter-track@2x.webp';
import nadezhdinIslandImage from '../../../assets/images/tracks/Nadezhdin-island.webp';
import nadezhdinIslandImage2x from '../../../assets/images/tracks/Nadezhdin-island@2x.webp';
import richVarietyImage from '../../../assets/images/tracks/Rich-variety.webp';
import richVarietyImage2x from '../../../assets/images/tracks/Rich-variety@2x.webp';
import lighthouseImage from '../../../assets/images/tracks/Lighthouse.webp';
import lighthouseImage2x from '../../../assets/images/tracks/Lighthouse@2x.webp';
import krylatskoeImage from '../../../assets/images/tracks/Krylatskoe.webp';
import krylatskoeImage2x from '../../../assets/images/tracks/Krylatskoe@2x.webp';
import cyclingTrackImage from '../../../assets/images/tracks/Cycling-track.webp';
import cyclingTrackImage2x from '../../../assets/images/tracks/Cycling-track@2x.webp';
import lakesImage from '../../../assets/images/tracks/lakes.webp';
import lakesImage2x from '../../../assets/images/tracks/lakes@2x.webp';

export type TrackType = {
    image: string;
    image2x: string;
    title: string;
    has_details: boolean;
    details: string;
    route_properties: string;
    isFeatured: boolean;
    featured_text?: string;
};

export const getFormattedTracks = (): TrackType[] => {
    const intl = useIntl();

    return [
        {
            image: nadezhdinIslandImage,
            image2x: nadezhdinIslandImage2x,
            title: intl.formatMessage({
                id: 'Paths_nj8L9r',
                defaultMessage: 'Остров Надеждина',
                description: 'карты'
            }),
            has_details: true,
            details: intl.formatMessage({
                id: 'Paths_bsT6IJ',
                defaultMessage:
                    'Карта посвящена Игорю Надеждину — потрясающему веломеханику и замечательному человеку. Тут есть все то, за что мы так любим острова: дорога вдоль моря, горный серпантин с потрясающими видами, отвесные скалы с водопадом, парусные корабли и воздушные шары. Есть и необычный маршрут через подземный туннель.',
                description: 'карты'
            }),
            route_properties: intl.formatMessage({
                id: 'Paths_iZJai0',
                defaultMessage:
                    'На карте представлено несколько маршрутов от коротких длиной 12 км и набором 36 метров до длинных (самый длинный маршрут 64 км с набором 705 м).',
                description: 'карты'
            }),
            isFeatured: false
        },
        {
            image: richVarietyImage,
            image2x: richVarietyImage2x,
            title: intl.formatMessage({
                id: 'Paths_wygipn',
                defaultMessage: 'Богатое разнообразие',
                description: 'карты'
            }),
            has_details: true,
            details: intl.formatMessage({
                id: 'Paths_Mcr2bu',
                defaultMessage:
                    'Карта не даст заскучать! Ощутите природу северного лета: скалистые горы, лес с животными,  небольшие озёра и разнообразие флоры. Кроме этого, специально для вас перекрыли современный Затерянный город с небоскрёбами и спокойный Солнечный город с низкоэтажной застройкой. Остудить свой пыл вы сможете в Морозной тундре среди снежных пейзажей, после чего придётся поработать на холмах Вересковой пустоши.',
                description: 'карты'
            }),
            route_properties: intl.formatMessage({
                id: 'Paths_aHZ+3i',
                defaultMessage: 'Длина маршрута 33 км, набор 570 метров.',
                description: 'карты'
            }),
            isFeatured: false
        },
        // {
        //     image: winterTrackImage,
        //     image2x: winterTrackImage2x,
        //     title: intl.formatMessage({
        //         id: 'Paths_IW9IRK',
        //         defaultMessage: 'Зимний трек',
        //         description: 'карты'
        //     }),
        //     has_details: true,
        //     details: intl.formatMessage({
        //         id: 'Paths_tUqvtq',
        //         defaultMessage:
        //             'Настоящий гоночный трек для автогонок со свежими следами шин. Кажется, эта трасса и есть Родина зимы в лучшем виде, иногда начинается настоящая метель, в морозном озере плавают корабли и все деревья и дома покрыты снегом. Но замерзнуть не получится, тут есть горки с градиентом до 20%, которые заставят вас хорошенько разогреться. На карте представлены два маршрута: по и против часовой стрелки.',
        //         description: 'карты'
        //     }),
        //     route_properties: intl.formatMessage({
        //         id: 'Paths_t+DSkR',
        //         defaultMessage: 'Длина круга 11,8 км, набор 236 м.',
        //         description: 'карты'
        //     }),
        //     isFeatured: false
        // },
        {
            image: krylatskoeImage,
            image2x: krylatskoeImage2x,
            title: intl.formatMessage({
                id: 'Paths_krylo',
                defaultMessage: 'Крылатское',
                description: 'карты'
            }),
            has_details: true,
            details: intl.formatMessage({
                id: 'Paths_krylodescr',
                defaultMessage:
                    'Малое кольцо легендарной кольцевой велотрассы на Крылатских холмах в Москве, а также большое кольцо с проездом по знаменитому Живописному мосту.',
                description: 'карты'
            }),
            route_properties: intl.formatMessage({
                id: 'Paths_kryloroute',
                defaultMessage: 'Длина круга 26,7 км, набор 320 м.',
                description: 'карты'
            }),
            isFeatured: false
        },
        {
            image: lighthouseImage,
            image2x: lighthouseImage2x,
            title: intl.formatMessage({
                id: 'Paths_lighthouse',
                defaultMessage: 'Маяк',
                description: 'карты'
            }),
            has_details: true,
            details: intl.formatMessage({
                id: 'Paths_lighthousedescr',
                defaultMessage: 'Маршрут вдоль побережья с небольшими холмами к живописному маяку.',
                description: 'карты'
            }),
            route_properties: intl.formatMessage({
                id: 'Paths_lighthouseroute',
                defaultMessage: 'Длина круга 20,6 км, набор 148 м.',
                description: 'карты'
            }),
            isFeatured: false
        },
        // {
        //     image: cyclingTrackImage,
        //     image2x: cyclingTrackImage2x,
        //     title: intl.formatMessage({
        //         id: 'Paths_cyclingTrack',
        //         defaultMessage: 'Велотрек',
        //         description: 'карты'
        //     }),
        //     has_details: true,
        //     details: intl.formatMessage({
        //         id: 'Paths_cyclingTrackdescr',
        //         defaultMessage:
        //             'Классический велотрек. Доступ к нему открывается во время проведения соревнований. Доступны такие быстрые и азартные дисциплины как гит, гонка на выбывание и гонка Темпо.',
        //         description: 'карты'
        //     }),
        //     route_properties: intl.formatMessage({
        //         id: 'Paths_cyclingTrackroute',
        //         defaultMessage: 'Длина трека 250 метров.',
        //         description: 'карты'
        //     }),
        //     isFeatured: true,
        //     featured_text: intl.formatMessage({
        //         id: 'Paths_cyclingTrackfeature',
        //         defaultMessage: 'Гонки',
        //         description: 'карты'
        //     })
        // },
        {
            image: lakesImage,
            image2x: lakesImage2x,
            title: intl.formatMessage({
                id: 'Paths_Mntlake',
                defaultMessage: 'Озёра и горы',
                description: 'карты'
            }),
            has_details: true,
            details: intl.formatMessage({
                id: 'Paths_Mntlakesdescr',
                defaultMessage:
                    'Новая холмистая карта в приятной локации с озёрами и горами. В центре карты можно найти копию Живописного моста, так же известного как мост в Серебряном Бору, который находится в Москве недалеко от известных Крылатских холмов.',
                description: 'карты'
            }),
            route_properties: intl.formatMessage({
                id: 'Paths_Mntlakeroute',
                defaultMessage: 'Длина маршрута 15,7 км, набор 250 метров.',
                description: 'карты'
            }),
            isFeatured: true,
            featured_text: intl.formatMessage({
                id: 'Paths_Mntlakefeature',
                defaultMessage: 'Новинка!',
                description: 'карты'
            })
        },
    ];
};
