import { FormattedMessage } from 'react-intl';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { APP_DOWNLOAD_LINK_AND, CURRENT_APP_VERSION } from '../../../../const';
import classes from './DownloadWin.module.css';

function DownloadAndroid() {
    return (
        <div className={classes['download-win']}>
            <div>
                <dl className={classes['download-win__requirements']}>
                    <div>
                        <dt>
                            <Typography
                                variant="h5"
                                component="p"
                                className={classes['download-win__subtitle']}
                            >
                                <FormattedMessage
                                    defaultMessage="Минимальные"
                                    id="downloadAppRequirementsMin"
                                    description="скачать"
                                />
                            </Typography>
                        </dt>
                        <dd className={classes['download-win__requirement']}>
                            <FormattedMessage
                                defaultMessage="Android OS 10 и выше"
                                id="downloadAppRequirementsMinAndroid"
                                description="скачать"
                            />
                        </dd>
                        <dd className={classes['download-win__requirement']}>
                            <FormattedMessage
                                defaultMessage="2 Гб свободного места"
                                id="downloadAppRequirementsMinSpaceAndroid"
                                description="скачать"
                            />
                        </dd>
                    </div>
                </dl>
                <p>Ссылка на скачивание доступна с телефона на базе Android без VPN.</p>
            </div>
            <div className={classes['download-win__details']}>
                <Link
                    className={classes['download-win__link']}
                    href={APP_DOWNLOAD_LINK_AND}
                    variant="h2"
                    color="info.main"
                    download
                >
                    <FormattedMessage
                        defaultMessage="Скачать"
                        id="downloadAppLink"
                        description="скачать"
                    />{' '}
                    <DownloadIcon className={classes['download-win__icon']} />
                </Link>
                <Typography variant="h4" component="p">
                    {CURRENT_APP_VERSION.android.size}{' '}
                    <FormattedMessage
                        defaultMessage="Мб"
                        id="downloadAppMb"
                        description="скачать"
                    />
                    ,{' '}
                    <FormattedMessage
                        defaultMessage="версия"
                        id="downloadAppVersion"
                        description="скачать"
                    />{' '}
                    {CURRENT_APP_VERSION.android.version}
                </Typography>
            </div>
        </div>
    );
}

export default DownloadAndroid;
