import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Telegram from '@mui/icons-material/Telegram';
import classes from './DownloadPlatforms.module.css';
import { FormattedMessage } from 'react-intl';

function DownloadIOS() {
    return (
        <>
            <p>
                iPhone 11 Pro (2019), iPhone 11 Pro Max (2019), iPhone 12 mini (2020), iPhone 12
                (2020), iPhone 12 Pro (2020), iPhone 12 Pro Max (2020), iPhone SE (2nd generation,
                2020), iPhone SE (3rd generation, 2022), iPhone 13 mini (2021), iPhone 13 (2021),
                iPhone 13 Pro (2021), iPhone 13 Pro Max (2021), iPhone 14, iPhone 14 Plus, iPhone 14
                Pro, iPhone 14 Pro Max, iPhone 15, iPhone 15 Plus, iPhone 15 Pro, iPhone 15 Pro Max
                iPad Pro 12.9 (3rd generation, 2018), iPad Pro 12.9 (4th generation, 2020), iPad Pro
                12.9 (5th generation, 2021), iPad Pro 11 (1st generation, 2018), iPad Pro 11 (2nd
                generation, 2020), iPad Pro 11 (3rd generation, 2021), iPad Pro 11 (4th generation,
                2023), iPad Air (3rd generation, 2019), iPad Air (4th generation, 2020), iPad Air
                (5th generation, 2022), iPad 10.2 (8th generation, 2020), iPad 10.2 (9th generation,
                2021), iPad 10.2 (10th generation, 2022), iPad Mini (5th generation, 2019), iPad
                Mini (6th generation, 2021)
            </p>
            <p>
                В текущий момент приложение находится на стадии допуска в App Store. Доступ можно получить из тестовой платформы. Для этого скачайте в App Store приложение <a href='https://apps.apple.com/us/app/testflight/id899247664'>TestFlight</a> и установите его. <br />
                После этого полностью рабочая версия WattAttack будет доступна по ссылке: <a href='https://testflight.apple.com/join/5tYFa6hR'>https://testflight.apple.com/join/5tYFa6hR</a>.
            </p>
        </>
    );
}

export default DownloadIOS;
